<template>
  <v-container fluid>
    <v-card-title>
      멘토의 매일 편지
      <v-spacer />
      <v-btn outlined to="/daily_letter/create">
        <v-icon left>mdi-plus</v-icon>
        등록
      </v-btn>
    </v-card-title>
    <v-card outlined>
      <v-data-table
        v-model="req.selected"
        :headers="headers"
        :items="res.data"
        :items-per-page="req.offset"
        show-select
        hide-default-footer
        @click:row="
          (item) => {
            $router.push(`${$route.path}/${item.id}`)
          }
        "
      >
        <template v-slot:item.publishedAt="{ item }">
          {{ date(item.publishedAt) }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ toStatus(item) }}
        </template>
        <template v-slot:footer>
          <v-divider />
          <v-container fluid class="row" no-gutters>
            <v-btn icon class="mr-3" @click="deleteLetterOfMentor">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-col cols="1">
              <v-select v-model="req.offset" :items="[5, 10, 15, 25, 50, 100]" outlined dense hide-details />
            </v-col>
            <v-spacer />
            <v-col>
              <v-pagination v-model="req.page" total-visible="13" :length="req.pageNumber" circle color="#ffd600" @input="getListLetterOfMentor" />
            </v-col>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs, watchEffect } from '@vue/composition-api'
import { date } from '@/helper'

export default defineComponent({
  setup(props, { root }) {
    const state = reactive({
      res: {
        data: [],
        totalCount: null,
      },
      req: {
        selected: [],
        page: 1,
        pageNumber: 1,
        offset: 15,
      },
      headers: [
        { text: 'no', value: 'id' },
        { text: '노출일', value: 'publishedAt' },
        { text: '노출상태', value: 'status' },
        { text: '멘토', value: 'Mentor.name' },
        { text: '내용', value: 'content' },
        { text: null, value: 'action' },
      ],
    })

    const getListLetterOfMentor = async () => {
      try {
        const listLetterOfMentor = await root.$store.dispatch('letterOfMentor/getListLetterOfMentor', {
          offset: state.req.offset,
          pageNumber: state.req.page,
        })

        state.res = listLetterOfMentor
        state.req.pageNumber = Math.ceil(listLetterOfMentor.totalCount / state.req.offset)
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const deleteLetterOfMentor = async () => {
      if (confirm(`${state.req.selected.length}개의 편지를 삭제합니다. 편지 삭제 후 되돌릴 수 없습니다.`)) {
        try {
          for (const e of state.req.selected) {
            await root.$store.dispatch('letterOfMentor/deleteLetterOfMentor', {
              id: Number(e.id),
            })
          }
          alert(`편지 ${state.req.selected.length}개가 정상적으로 삭제되었습니다.`)
          state.req.selected = []
          getListLetterOfMentor()
        } catch (err) {
          alert(err)
          console.log(err)
        }
      }
    }

    const toStatus = (item) => {
      if (item.publishedAt) {
        if (new Date(item.publishedAt) > new Date()) {
          return '예약'
        } else if (new Date(item.publishedAt).toLocaleDateString() === new Date().toLocaleDateString()) {
          return '노출'
        } else return '비노출'
      } else return '비노출'
    }

    watchEffect(() => {
      getListLetterOfMentor()
    })

    onBeforeMount(() => {
      getListLetterOfMentor()
    })

    return {
      ...toRefs(state),
      getListLetterOfMentor,
      deleteLetterOfMentor,
      toStatus,
      date,
    }
  },
})
</script>
